import { AutoMode } from '@mui/icons-material';
import { Box, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Conversation } from 'Types/conversation';
import { Prompt } from 'Types/prompt';
import { HomePageStats } from 'Types/stats';

import UserStatsIcon from '../../assets/people.svg';
import ChatStatsIcon from '../../assets/chat-stats-icon.svg';
import MessageStatsIcon from '../../assets/message-stats-icon.svg';
import { getHomePageStats } from '../../services/stats';

interface TitleBoxProps {
  useFunctions: boolean;
}

const TitleBox = styled('div')<TitleBoxProps>(({ useFunctions }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'start',
  marginBottom: '30px',
  marginTop: !useFunctions ? '30px' : '0px',
  width: '70%',
}));

const InfoCard = styled(Box)({
  width: '280px',
  minHeight: '305px',
  textAlign: 'start',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  border: '2px solid #6cb0f2',

  h4: {
    marginLeft: '20px',
    marginTop: '30px',
    marginBottom: '20px',
  },
});

const Link = styled('span')({
  textDecoration: 'underline',
  ':hover': {
    cursor: 'pointer',
  },
});

const StatsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
});

const StyledStatsIcon = styled('img')({
  width: '60px',
  marginRight: '20px',
});

export type ChatInfoProps = {
  currentConversation?: Conversation;
  currentPrompt?: Prompt;
  shouldShowInfo: boolean;
  useFunctions: boolean;
};

const ChatInfo = ({
  currentConversation,
  currentPrompt,
  shouldShowInfo,
  useFunctions,
}: ChatInfoProps) => {
  const [stats, setStats] = useState<HomePageStats>({
    numUsers: 0,
    numActiveConversations: 0,
    numMessages: 0,
  });
  const [shouldFetchStats, setShouldFetchStats] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStats = async () => {
      const newStats = await getHomePageStats();
      setStats(newStats);
      setShouldFetchStats(false);
    };
    if (shouldFetchStats) {
      fetchStats();
    }
  }, [shouldFetchStats]);

  return (
    <div>
      {currentPrompt?.userEditRequired === false ||
      (currentConversation && currentConversation.prompt?.userEditRequired === false) ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '70% ' }}>
            <AutoMode sx={{ width: '36px', height: '36px', marginY: '30px' }} />
            <Typography sx={{ fontWeight: '700', fontSize: '36px', marginBottom: '30px' }}>
              {currentPrompt
                ? currentPrompt.title
                : currentConversation && currentConversation.prompt?.title}
            </Typography>
            <Typography sx={{ fontSize: '20px', marginBottom: '30px' }}>
              {currentPrompt
                ? currentPrompt.description
                : currentConversation && currentConversation.prompt?.description}
            </Typography>
          </div>
        </div>
      ) : shouldShowInfo ? (
        <div>
          <TitleBox useFunctions={useFunctions}>
            <Typography
              variant="h4"
              sx={{ marginBottom: '10px', fontWeight: '700', fontSize: '40px' }}
            >
              Welcome to Beacon
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: '400', fontSize: '16px' }}>
              Generate ideas, summarize notes, or recap a meeting with our conversational AI chat bot.
            </Typography>
          </TitleBox>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '10px',
              marginBottom: '20px',
            }}
          >
            <InfoCard sx={{ '&:hover': { cursor: 'default' } }}>
              <Typography
                variant="h4"
                sx={{ color: '#062541', fontWeight: '700', fontSize: '24px' }}
              >
                Stats
              </Typography>
              <div
                style={{
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  margin: '0 20px 20px 20px',
                  gap: '30px',
                  display: 'flex',
                }}
              >
                <StatsContainer>
                  <StyledStatsIcon src={UserStatsIcon} />
                  <Typography>
                    <span style={{ fontWeight: 'bold' }}>{stats.numUsers}</span> Users
                  </Typography>
                </StatsContainer>
                <StatsContainer>
                  <StyledStatsIcon src={ChatStatsIcon} />
                  <Typography>
                    <span style={{ fontWeight: 'bold' }}>{stats.numActiveConversations}</span> Chats
                    Today
                  </Typography>
                </StatsContainer>
                <StatsContainer>
                  <StyledStatsIcon src={MessageStatsIcon} />
                  <Typography>
                    <span style={{ fontWeight: 'bold' }}>{stats.numMessages}</span> Messages
                  </Typography>
                </StatsContainer>
              </div>
            </InfoCard>
            <InfoCard>
              <Typography
                variant="h4"
                sx={{ color: '#062541', fontWeight: '700', fontSize: '24px' }}
              >
                Prompts
              </Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  margin: '0 20px 20px 20px',
                  flexGrow: 1,
                }}
              >
                <Typography>
                  Be sure to check out the prompt library, a.k.a.{' '}
                  <Link onClick={() => navigate('/prompts')}>Prompts</Link>
                  . <br />
                  <br />
                  Think of them as little “head starts” that can help you quickly get the info you
                  want.
                </Typography>
              </div>
            </InfoCard>
            <InfoCard>
              <Typography
                variant="h4"
                sx={{ color: '#062541', fontWeight: '700', fontSize: '24px' }}
              >
                FAQ
              </Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  margin: '0 20px 20px 20px',
                  flexGrow: 1,
                }}
              >
                <Typography>
                  Not sure where to start? Check out our index of{' '}
                  <Link onClick={() => window.open('https://bdtmsd.sharepoint.com/sites/ai', '_blank')}>common questions</Link> — with super-smart
                  answers!
                </Typography>
              </div>
            </InfoCard>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ChatInfo;
