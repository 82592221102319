import { MenuOpen } from '@mui/icons-material';
import { Button, Container, List, ListItem, styled, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Conversation } from '../../Types/conversation';
import ChatCard from './ChatCard';

const NewChatButton = styled(Button)({
  width: '80%',
  margin: '8px 16px',
});

const NewChatButtonContainer = styled('div')({
  display: 'flex',
  width: '100%',
});

const CenteredContentList = styled(List)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const IconContainer = styled('span')({
  color: 'gray',
  transition: 'all 0.2s ease-in-out',
  display: 'flex',
  height: '20px',

  span: {
    color: 'black',
    marginLeft: '5px',
  },
});

export type ChatHistoryProps = {
  conversations: Conversation[];
  handleConversationUpdate: (conversationId?: string) => void;
  closeHistoryDrawer: () => void;
};

const ChatHistory = ({
  conversations,
  handleConversationUpdate,
  closeHistoryDrawer,
}: ChatHistoryProps): JSX.Element => {
  const navigate = useNavigate();

  const handleNewChat = async () => {
    navigate('/chat');
    // So default chat info shows up after clicking new chat
    handleConversationUpdate(undefined);
  };

  return (
    <Container
      style={{ overflowY: 'auto', padding: '0px', height: 'inherit' }}
    >
      <CenteredContentList>
        <NewChatButtonContainer>
          <NewChatButton
            variant="contained"
            sx={{ bgcolor: '#181830' }}
            onClick={() => handleNewChat()}
          >
            New Chat
          </NewChatButton>
          <Tooltip title={<Typography variant="caption"> Close History Panel </Typography>}>
            <IconContainer sx={{ marginTop: '13px' }} onClick={closeHistoryDrawer}>
              <MenuOpen sx={{ cursor: 'pointer' }} />
            </IconContainer>
          </Tooltip>
        </NewChatButtonContainer>
        {conversations.map((conversation) => (
          <ListItem key={conversation.id}>
            <ChatCard
              conversation={conversation}
              onConversationCardClick={handleConversationUpdate}
            />
          </ListItem>
        ))}
      </CenteredContentList>
    </Container>
  );
};

export default ChatHistory;
