import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import * as React from 'react';

type Props = {
  snackbarKey: SnackbarKey;
};

function SnackbarCloseButton({ snackbarKey }: Props) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton style={{ color: 'white' }} onClick={() => closeSnackbar(snackbarKey)}>
      <Close />
    </IconButton>
  );
}

export default SnackbarCloseButton;
