export enum MessageFrom {
  AI = 'AI',
  USER = 'USER',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum Tag {
  COMMUNICATION = 'Communication',
  FINANCE_AND_ACCOUNTING = 'Finance and Accounting',
  GENERAL = 'General',
  INVESTMENTS = 'Investments',
  CODE_GENERATION = 'Code Generation',
  HUMAN_RESOURCES = 'Human Resources',
  MEETINGS = 'Meetings',
  LEGAL_AND_COMPLIANCE = 'Legal and Compliance',
  CLIENT_ENGAGEMENT = 'Client Engagement',
  TROUBLESHOOTING = "Troubleshooting",
  SUMMARY = "Summary",
  ADMINISTRATIVE = "Administrative",
  BRAINSTORMING = "Brainstorming",
  TECHNOLOGY = "Technology",
  CLEANUP_AND_IMPROVEMENT = "Cleanup and Improvement"
}

export enum FunctionName {
  HELLO_WORLD = 'helloWorld',
}

export const FunctionNameMap = {
  [FunctionName.HELLO_WORLD]: 'Hello World',
};
