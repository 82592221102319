const parseToCsvSafeValue = (str: string) => {
  //Adapted from: https://codepen.io/dcode-software/pen/WBGOyd

  let parsedValue = str;

  // Replace all double quotes with two double quotes
  parsedValue = parsedValue.replace(/"/g, `""`);

  // If value contains comma, new-line or double-quote, enclose in double quotes
  parsedValue = /[",\n]/.test(parsedValue) ? `"${parsedValue}"` : parsedValue;

  return parsedValue;
};

export const parseTableToCSV = (str: string) => {
  return str
    .split('\n')
    .map((row) => row.split('\t').map(parseToCsvSafeValue).join(','))
    .join('\n');
};
