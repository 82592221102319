import styled from '@emotion/styled';
import { ContentCopy } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

import useCopyToClipboard from '../../hooks/useCopyToClipboard';

interface Props {
  children: React.ReactNode;
}

const CodeCopyButtonComponent = styled('span')({
  position: 'absolute',
  top: '-1px',
  right: '0',
  cursor: 'pointer',
  zIndex: 1,
});

export default function CodeCopyButton({ children }: Props) {
  const { copySuccess, copyToClipboard } = useCopyToClipboard();

  // this color is the green color of strings in the code blocks for uniformity
  const iconColor: string = copySuccess ? 'rgb(171, 227, 56)' : 'white';
  const codeText = (children as React.ReactElement[])[0].props.children[0];

  return (
    <CodeCopyButtonComponent>
      <IconButton
        onClick={() => copyToClipboard(codeText)}
        sx={{ color: iconColor, transition: 'all 0.2s ease-in-out' }}
      >
        <ContentCopy sx={{ height: '1rem' }} />
      </IconButton>
    </CodeCopyButtonComponent>
  );
}
