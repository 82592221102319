import React, { createContext, useContext, useMemo, useState } from 'react';
import { Step } from 'react-joyride';

import { AppState } from '../../Types/joyride';

const appState = {
  run: false,
  stepIndex: 0,
  steps: [] as Step[],
  tourActive: true,
};

export const JoyrideContext = createContext({
  tourState: appState,
  setTourState: (() => undefined) as (newState: AppState) => void,
});

interface JoyrideProviderProps {
  children: React.ReactNode;
}

export const JoyrideProvider: React.FC<JoyrideProviderProps> = (props) => {
  const [tourState, setTourState] = useState<AppState>(appState);

  const value = useMemo(
    () => ({
      tourState,
      setTourState,
    }),
    [tourState, setTourState]
  );

  return <JoyrideContext.Provider value={value} {...props} />;
};

export const useJoyride = (): {
  tourState: AppState;
  setTourState: (newState: AppState) => void;
} => {
  const joyrideContext = useContext(JoyrideContext);
  if (!joyrideContext) {
    throw new Error('useJoyride must be used within a JoyrideProvider');
  }
  return joyrideContext;
};
