import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

const useCopyToClipboard = () => {
  const [copySuccess, setCopySuccess] = useState<boolean>(false);

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 1000);
    } catch (error) {
      enqueueSnackbar('Failed to copy to clipboard', {
        variant: 'error',
      });
    }
  };

  return { copySuccess, copyToClipboard };
};

export default useCopyToClipboard;
