import { Typography } from '@mui/material';
import React from 'react';

export const GuidedSteps = [
  {
    title: 'New Feature - My Prompts',
    target: '#my-prompts',
    content: <Typography>Click here to get started creating and sharing prompts!</Typography>,
    disableBeacon: true,
  },
];
