import styled from '@emotion/styled';
import { Close, ExpandMore, HelpOutline } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Slider,
  Tooltip,
  Typography,
} from '@mui/material';
import { FormApi } from 'final-form';
import { Switches, TextField } from 'mui-rff';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';

import { createOrUpdatePrompt } from '../../services/prompts';
import { FunctionName, Tag } from '../../Types/enums';
import { CreatePrompt, Prompt } from '../../Types/prompt';
import { useAuth } from '../Providers/AuthProvider';
import ModificationConfirmationDialog from './ModificationConfirmationDialog';

const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  margin: '0 20px 20px 20px',
});

const StyledAccordion = styled(Accordion)({
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  marginBottom: '10px',
});

const StyledGridContainer = styled(Grid)({
  display: 'flex',
  flexGrow: 1,
});

const PROMPT_DEFAULTS = {
  title: '',
  description: '',
  prompt: '',
  userEditRequired: false,
  published: false,
  metadata: { temperature: 0.5 },
  tags: [] as Tag[],
  functions: [] as FunctionName[],
  featured: false,
};

const PromptEdit = ({
  prompt,
  isOpen,
  setIsOpen,
  onSuccess,
  readOnly,
}: {
  prompt?: Prompt;
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  onSuccess: () => void;
  readOnly?: boolean;
}) => {
  const [shouldSaveCopy, setShouldSaveCopy] = useState(false);
  const [isModificationConfirmationOpen, setIsModificationConfirmationOpen] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState<CreatePrompt>(prompt || PROMPT_DEFAULTS);
  const [showFeatured, setShowFeatured] = useState(prompt?.published);
  const [modalTile, setModalTitle] = useState<string>(
    'Are you sure you want to update this published prompt?'
  );

  const { isAdmin } = useAuth();

  useEffect(() => {
    if (!isOpen) {
      setShowFeatured(false);
    } else {
      setShowFeatured(prompt?.published);
    }
  }, [isOpen, prompt?.published]);

  const handleConfirm = async (passedPrompt?: CreatePrompt) => {
    let newPrompt = { ...prompt };

    if (passedPrompt) newPrompt = { ...newPrompt, ...passedPrompt };
    else newPrompt = { ...newPrompt, ...currentPrompt };

    if ('liked' in newPrompt) delete newPrompt.liked;
    if ('pinned' in newPrompt) delete newPrompt.pinned;
    if ('likedCount' in newPrompt) delete newPrompt.likedCount;
    if ('pinnedOrder' in newPrompt) delete newPrompt.pinnedOrder;
    if ('featuredOrder' in newPrompt) delete newPrompt.featuredOrder;
    if ('createdByName' in newPrompt) delete newPrompt.createdByName;
    if ('usageCount' in newPrompt) delete newPrompt.usageCount;

    if (shouldSaveCopy) {
      if ('id' in newPrompt) delete newPrompt.id;
      if ('createdAt' in newPrompt) delete newPrompt.createdAt;
      if ('createdBy' in newPrompt) delete newPrompt.createdBy;
      if ('updatedBy' in newPrompt) delete newPrompt.updatedBy;
      if ('deleted' in newPrompt) delete newPrompt.deleted;

      await createOrUpdatePrompt(newPrompt as CreatePrompt);
    } else {
      await createOrUpdatePrompt(newPrompt as CreatePrompt, prompt?.id);
    }

    enqueueSnackbar('Prompt saved!', { variant: 'success' });
    onSuccess();
    setIsOpen(false);
  };

  const onSubmit = async (values: CreatePrompt) => {
    const newPrompt = {
      title: shouldSaveCopy ? values['title'] + ' Copy' : values['title'],
      description: values['description'],
      prompt: values['prompt'],
      published: shouldSaveCopy ? false : values['published'],
      userEditRequired: values['userEditRequired'],
      metadata: values['metadata'],
      tags: values['tags'],
      functions: values['functions'],
      featured: values['featured'],
    } as CreatePrompt;
    setCurrentPrompt(newPrompt);

    if ((prompt?.published && !shouldSaveCopy) || (!prompt?.published && newPrompt.published)) {
      if ((prompt?.published === false || !prompt) && newPrompt.published) {
        setModalTitle('Are you sure you want to publish this prompt?');
      } else {
        setModalTitle('Are you sure you want to update this published prompt?');
      }
      setIsModificationConfirmationOpen(true);
    } else {
      await handleConfirm(newPrompt);
    }
  };

  const shouldShowFeatured = (form: FormApi<CreatePrompt, Partial<CreatePrompt>>) => {
    if (showFeatured) {
      form.change('featured', false);
      setShowFeatured(false);
    } else {
      setShowFeatured(true);
    }
  };

  return (
    <>
      <ModificationConfirmationDialog
        onConfirm={() => handleConfirm()}
        onCancel={() => {
          setIsModificationConfirmationOpen(false);
          setIsOpen(true);
        }}
        isOpen={isModificationConfirmationOpen}
        setIsOpen={setIsModificationConfirmationOpen}
        title={modalTile}
      />
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth>
        <DialogTitle>{prompt ? 'Edit Prompt' : 'New Prompt'}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setIsOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <FinalForm
          initialValues={{ ...PROMPT_DEFAULTS, ...prompt }}
          onSubmit={onSubmit}
          render={({ handleSubmit, form, values }) => (
            <StyledForm onSubmit={handleSubmit}>
              <TextField
                label="Title"
                name={'title'}
                helperText="Give your prompt a name"
                required
                InputLabelProps={{ shrink: true }}
                disabled={readOnly}
              />
              <TextField
                label="Description"
                name={'description'}
                helperText="Provide a quick description of your prompt (Beacon cannot see this)"
                required
                multiline
                minRows={2}
                InputLabelProps={{ shrink: true }}
                disabled={readOnly}
              />
              <TextField
                label="Prompt Recipe"
                name={'prompt'}
                helperText="Paste your prompt recipe and instructions for Beacon here"
                required
                multiline
                minRows={4}
                InputLabelProps={{ shrink: true }}
                disabled={readOnly}
              />
              <Autocomplete
                multiple
                id="tags-standard"
                disabled={readOnly}
                options={Object.values(Tag)}
                value={values.tags}
                onChange={(_, newValue) => {
                  form.change('tags', newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} name="tagInput" variant="standard" label="Tags"
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    const inputValue = (e.target as HTMLInputElement).value.trim() as Tag;
                    if (e.key === 'Tab') {
                      e.preventDefault();
  
                      if (
                        inputValue &&
                        !values.tags.includes(inputValue) &&
                        Object.values(Tag).includes(inputValue)
                      ) {
                        form.change('tags', [...values.tags, inputValue]);
                      }
                    }
                  }} />
                )}
              />
              {/* Add || isAdmin to the if for admins to also see functions, that was removed because no functions 
              are implemented */}
              {(localStorage.getItem('showFunctions') === 'true') && (
                <Autocomplete
                  multiple
                  disabled={readOnly}
                  id="functions-standard"
                  options={Object.values(FunctionName)}
                  value={values.functions}
                  onChange={(_, newValue) => {
                    form.change('functions', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="functionInput"
                      variant="standard"
                      label="Functions"
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        const inputValue = (e.target as HTMLInputElement).value.trim() as FunctionName;
                        if (e.key === 'Tab') {
                          e.preventDefault();
                          if (
                            inputValue &&
                            !values.functions.includes(inputValue) &&
                            Object.values(FunctionName).includes(inputValue)
                          ) {
                            form.change('functions', [...values.functions, inputValue]);
                          }
                        }
                      }}
                    />
                  )}
                />
              )}
              <Switches
                disabled={readOnly}
                helperText={
                  'If toggled on, the prompt text will appear in the message field to allow editing before sending. If toggled off, the prompt will automatically be triggered to send once the user clicks the prompt card.'
                }
                name={'userEditRequired'}
                data={{ label: 'User Edit Required?', value: false }}
              />
              <Switches
                disabled={readOnly}
                helperText={'This controls whether or not the prompt is published for communal use'}
                name={'published'}
                onClick={() => shouldShowFeatured(form)}
                data={{ label: 'Publish this prompt?', value: false }}
              />
              {isAdmin && showFeatured && (
                <>
                  <Switches
                    helperText={
                      'This controls whether or not the prompt is featured for communal use'
                    }
                    name={'featured'}
                    data={{ label: 'Make this prompt featured?', value: false }}
                  />
                </>
              )}
              <StyledAccordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel-content"
                  id="panel-content"
                >
                  <Typography>Advanced Settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ display: 'flex' }}>
                    <Typography sx={{ marginRight: '10px' }}>Temperature</Typography>
                    <Tooltip
                      title={
                        <Typography variant="caption">
                          Temperature determines how analytical (lower) or creative (higher) you
                          want the response to be. 0 is good for an objective response and 0.7-0.8
                          is good for a creative response.
                        </Typography>
                      }
                    >
                      <HelpOutline />
                    </Tooltip>
                  </div>
                  <StyledGridContainer container={true}>
                    <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                      🤖
                    </Grid>
                    <Grid item xs={10}>
                      <Field name="metadata.temperature">
                        {({ input }) => (
                          <Slider
                            min={0}
                            max={1}
                            step={0.1}
                            marks
                            disabled={readOnly}
                            valueLabelDisplay="auto"
                            value={input.value}
                            onChange={(_, newValue) => input.onChange(newValue)}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                      🍄
                    </Grid>
                  </StyledGridContainer>
                </AccordionDetails>
              </StyledAccordion>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Button
                  type="submit"
                  onClick={() => setShouldSaveCopy(true)}
                  variant="outlined"
                  color="success"
                  sx={{ width: '45%' }}
                >
                  Copy To My Prompts
                </Button>
                {!readOnly && (
                  <Button
                    id="save-button"
                    type="submit"
                    disabled={readOnly}
                    onClick={() => setShouldSaveCopy(false)}
                    variant="contained"
                    sx={{ width: '45%' }}
                  >
                    Save
                  </Button>
                )}
              </div>
            </StyledForm>
          )}
        />
      </Dialog>
    </>
  );
};

export default PromptEdit;
