import { User } from 'Types/user';

import { CreatePrompt, Prompt } from '../Types/prompt';
import { API_URL, enrichedFetch } from './api';

export const findPrompts = (
  publishedOnly: boolean,
  userId?: User['id'],
  featuredOnly?: boolean,
  pinned?: boolean,
  liked?: boolean
): Promise<Prompt[]> => {
  let url = `${API_URL}/prompts?publishedOnly=${publishedOnly}`;

  if (featuredOnly) {
    url += `&featuredOnly=${featuredOnly}`;
  }
  if (userId) {
    url += `&userId=${userId}`;
  }
  if (pinned !== undefined) {
    url += `&pinned=${pinned}`;
  }
  if (liked !== undefined) {
    url += `&liked=${liked}`;
  }

  return enrichedFetch(url) as Promise<Prompt[]>;
};

export const findPrompt = (promptId: Prompt['id']): Promise<Prompt> => {
  return enrichedFetch(`${API_URL}/prompts/prompt/${promptId}`) as Promise<Prompt>;
};

export const createOrUpdatePrompt = (
  prompt: CreatePrompt,
  promptId?: Prompt['id']
): Promise<Prompt> => {
  return enrichedFetch(`${API_URL}/prompts/${promptId ?? ''}`, {
    method: promptId ? 'PUT' : 'POST',
    body: JSON.stringify(prompt),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Prompt>;
};

export const deletePrompt = async (promptId: Prompt['id']): Promise<void> => {
  await enrichedFetch(`${API_URL}/prompts/${promptId}`, {
    method: 'DELETE',
  });
};
