import { MenuOpen } from '@mui/icons-material';
import { Checkbox, Divider, FormControlLabel, styled, Tooltip, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';

import { Tag } from '../../Types/enums';

const FiltersContainer = styled('div')({
  overflowY: 'auto',
  padding: '0px',
  height: 'inherit',
  userSelect: 'none',
});

const TitleRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: '15px',
  paddingLeft: '24px',
});

const CheckboxContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '20px',
  marginTop: '5px',
  paddingRight: '15px',
  paddingLeft: '24px',
});

const IndividualCheckboxContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

type TagCounts = {
  [key in Tag]: number;
};

export type PromptFiltersProps = {
  tagOptions: TagCounts;
  handleTagUpdate: (event: ChangeEvent<HTMLInputElement>, tag: Tag) => void;
  closeFilterDrawer: () => void;
};

const PromptFilters = ({
  tagOptions,
  handleTagUpdate,
  closeFilterDrawer,
}: PromptFiltersProps): JSX.Element => {
  return (
    <FiltersContainer>
      <TitleRow>
        <Typography
          variant="h5"
          sx={{
            fontSize: '16px',
            fontWeight: '700',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          Filters
        </Typography>
        <Tooltip title={<Typography variant="caption"> Close Filter Panel </Typography>}>
          <div style={{ color: 'grey' }} onClick={closeFilterDrawer}>
            <MenuOpen sx={{ cursor: 'pointer' }} />
          </div>
        </Tooltip>
      </TitleRow>
      <Divider sx={{ width: '100%', marginBottom: '20px' }} />
          <CheckboxContainer>
            {(() => {
              const total = Object.keys(Tag).reduce((acc, tag) => {
                const tagName = Tag[tag as keyof typeof Tag];
                return acc + tagOptions[tagName];
              }, 0);

              if (total > 0) {
                return Object.keys(Tag).map((tag, index2: number) => {
                const tagName = Tag[tag as keyof typeof Tag];
                const tagCount = tagOptions[tagName];
                return tagCount > 0 ? (
                  <IndividualCheckboxContainer
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      fontWeight: '500',
                    }}
                    key={index2}
                  >
                    <FormControlLabel
                      key={index2}
                      control={<Checkbox onChange={(event) => handleTagUpdate(event, tagName)} />}
                      label={`${tagName}`}
                      sx={{
                        userSelect: 'none',
                        width: '85%',
                        display: 'flex',
                        alignItems: 'flex-start',
                        span: { display: 'flex', alignItems: 'flex-start', paddingTop: '0px' },
                      }}
                    />
                    <Typography style={{ float: 'right', color: 'grey', fontWeight: '500' }}>
                      {tagOptions[tagName]}
                    </Typography>
                  </IndividualCheckboxContainer>
                ) : null;
              });
            } else {
              return (
                <Typography
                  variant="h6"
                  sx={{ fontWeight: '500', fontSize: '16px', color: 'grey' }}
                >
                  No tags found
                </Typography>
              );
            }
            })()}
          </CheckboxContainer>
    </FiltersContainer>
  );
};

export default PromptFilters;
