import { Container, styled, Typography } from '@mui/material';
import React from 'react';

const FAQPageDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '20px',
  paddingRight: '200px',
  paddingLeft: '200px',
});

const QuestionTypeTitle = styled(Typography)({
  variant: 'subtitle1',
  fontWeight: 'bold',
  marginBottom: '20px',
});

const QuestionLinkContainer = styled(Typography)({
  variant: 'subtitle2',
  marginBottom: '25px',

  a: {
    color: 'black',
  },
});

interface QuestionAnswer {
  question: string;
  answer: string;
}
const FAQList = {
  Capabilities: [
    {
      question: 'What is the difference between the “Chat” and “Prompts” main menu options?',
      answer:
        'The “Chat” option works just like common Generative AI solutions like ChatGPT. You can chat in the main window on the right and see your previous chats on the left. The “Prompt” section of Beacon allows you to browse pre-engineered prompts that have been created to support specific tasks (like write user stories) or workflows (like ingesting notes and providing specifically formatted output).',
    },
  ],
  Usage: [
    {
      question: 'Is there a limit to the number of questions I can ask Beacon?',
      answer:
        'The limit is based on tokens, while not an exact parallel, you can think of tokens like a character limit. The limit is very high for the average user, but can reach a maximum if you use a single chat to perform a complex task. A good rule of thumb is if you change topics, simply create a new chat.',
    },
  ],
  'Administration and Support': [
    
  ],
};

const FAQ = (): JSX.Element => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', overflowY: 'auto', height: '100%' }}>
      <Container sx={{ bgcolor: 'white', height: 'fit-content', margin: '0px' }}>
        <FAQPageDiv>
          <Typography variant="h4" style={{ fontWeight: '700', fontSize: '36px' }}>
            Frequently Asked Questions
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            <div style={{ width: '45%' }}>
              <QuestionTypeTitle>Capabilities</QuestionTypeTitle>
              {FAQList['Capabilities'].map((questionAnswer: QuestionAnswer, index: number) => {
                return (
                  <QuestionLinkContainer key={index}>
                    <a
                      href={`#c${index}`}
                      style={{
                        marginBottom: '30px',
                      }}
                    >
                      {questionAnswer.question}
                    </a>
                  </QuestionLinkContainer>
                );
              })}
            </div>

            <div style={{ width: '45%' }}>
              <QuestionTypeTitle>Usage</QuestionTypeTitle>
              {FAQList['Usage'].map((questionAnswer: QuestionAnswer, index: number) => {
                return (
                  <QuestionLinkContainer key={index}>
                    <a
                      href={`#u${index}`}
                      style={{
                        marginBottom: '30px',
                      }}
                    >
                      {questionAnswer.question}
                    </a>
                  </QuestionLinkContainer>
                );
              })}
              <QuestionTypeTitle>Administration and Support</QuestionTypeTitle>
              {FAQList['Administration and Support'].map(
                (questionAnswer: QuestionAnswer, index: number) => {
                  return (
                    <QuestionLinkContainer key={index}>
                      <a
                        href={`#a${index}`}
                        style={{
                          marginBottom: '30px',
                        }}
                      >
                        {questionAnswer.question}
                      </a>
                    </QuestionLinkContainer>
                  );
                }
              )}
            </div>
          </div>

          <br />
          <br />
          <Typography sx={{ fontSize: '24px', fontWeight: '700', marginBottom: '20px' }}>
            Capabilities
          </Typography>
          {FAQList['Capabilities'].map((questionAnswer: QuestionAnswer, index: number) => {
            return (
              <div id={`c${index}`} key={index}>
                <Typography sx={{ fontSize: '20px', fontWeight: '700', marginBottom: '20px' }}>
                  {questionAnswer.question}
                </Typography>
                <Typography style={{ marginBottom: '30px' }}>{questionAnswer.answer}</Typography>
              </div>
            );
          })}
          <Typography
            sx={{ fontSize: '24px', fontWeight: '700', marginBottom: '20px', marginTop: '20px' }}
          >
            Usage
          </Typography>
          {FAQList['Usage'].map((questionAnswer: QuestionAnswer, index: number) => {
            return (
              <div id={`u${index}`} key={index}>
                <Typography sx={{ fontSize: '20px', fontWeight: '700', marginBottom: '20px' }}>
                  {questionAnswer.question}
                </Typography>
                <Typography style={{ marginBottom: '30px' }}>{questionAnswer.answer}</Typography>
              </div>
            );
          })}
          <Typography
            sx={{ fontSize: '24px', fontWeight: '700', marginBottom: '20px', marginTop: '20px' }}
          >
            Administration and Support
          </Typography>
          {FAQList['Administration and Support'].map(
            (questionAnswer: QuestionAnswer, index: number) => {
              return (
                <div id={`a${index}`} key={index}>
                  <Typography sx={{ fontSize: '20px', fontWeight: '700', marginBottom: '20px' }}>
                    {questionAnswer.question}
                  </Typography>
                  <Typography style={{ marginBottom: '30px' }}>{questionAnswer.answer}</Typography>
                </div>
              );
            }
          )}
        </FAQPageDiv>
      </Container>
    </div>
  );
};

export default FAQ;
