import styled from '@emotion/styled';
import {
  AdminPanelSettings,
  FeedbackOutlined,
  LogoutOutlined,
  OpenInNew,
  Person,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { updateUser } from '../services/users';
import { User } from '../Types/user';
import FeedbackDialog from './FeedbackDialog';
import { useAuth } from './Providers/AuthProvider';
import { useJoyride } from './Providers/JoyrideProvider';

import BDTMSD from '../assets/BDT_MSD.png';

const IconContainer = styled('span')({
  color: 'gray',
  transition: 'all 0.2s ease-in-out',
  display: 'flex',

  span: {
    color: 'black',
    marginLeft: '5px',
  },
});

const LogoContainer = styled('div')({
  width: '140px',
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  cursor: 'pointer',
  marginRight: '10px',
  zIndex: '999'
});

const RoutesContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  minHeight: 'inherit',
  alignItems: 'center',
});

const NavBarContainer = styled('div')({
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  width: '100%',
  minHeight: 'inherit',
  justifyContent: 'space-between',
});

interface AppBarProps {
  showBar: boolean;
}

const StyledAppBar = styled(AppBar)<AppBarProps>(({ showBar }) => ({
  background: showBar ? 'linear-gradient(to right, #181830, #542C41)' : '#010207',
  color: 'white',
  boxShadow: 'none',
}));

const NavBarMenuItem = styled(MenuItem)({
  '&:hover': {
    outline: '1px solid #103e6a',
    backgroundColor: '#E2EFFC',
    color: 'black'
  },

  '&.Mui-selected': {
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      backgroundColor: '#E2EFFC',
    },
  },

  borderRadius: '4px',
  marginRight: '10px',
});

const StyledBox = styled(Box)({
  width: '350px',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
  alignItems: 'center',
  position: 'absolute',
  right: '0px',
  '& *': { color: 'white !important' } 
});

type PageRoute = {
  name: string;
  route: string;
};

const routes: PageRoute[] = [
  {
    name: 'Chat',
    route: '/chat',
  },
  {
    name: 'Prompts',
    route: '/prompts',
  },
  {
    name: 'My Prompts',
    route: '/my-prompts',
  },
];

export type NavBarProps = {
  llm: string;
  setLLM: (llm: string) => void;
};

const NavBar = ({ llm, setLLM }: NavBarProps): JSX.Element => {
  const { currentUser, isAdmin, logout } = useAuth();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { tourState, setTourState } = useJoyride();

  useEffect(() => {
    if (!currentUser?.metadata.tourCompleted && tourState.tourActive === true) {
      setTourState({ ...tourState, ...{ stepIndex: 0, run: true, tourActive: false } });
    }
  });

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    setAnchorElUser(null);
    logout();
  };

  const handleFeedback = () => {
    setFeedbackOpen(true);
  };

  return (
    <StyledAppBar position="static" showBar={currentUser ? true : false}>
      <FeedbackDialog isOpen={feedbackOpen} setIsOpen={setFeedbackOpen} />
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <NavBarContainer>
            {currentUser ? (
              <>
                <LogoContainer onClick={() => navigate('/')}>
                  <img src={BDTMSD} alt='BDT MSD Logo' style={{ height: '30px' }} />
                </LogoContainer>
                <RoutesContainer>
                  {routes.map((page) => (
                    <NavBarMenuItem
                      key={page.name}
                      id={page.route.substring(1)}
                      onClick={async () => {
                        if (page.name === 'My Prompts') {
                          setTourState({ ...tourState, ...{ run: false, tourActive: false } });
                          await updateUser({
                            ...currentUser,
                            ...{ metadata: { tourCompleted: true } },
                          } as User);
                        }
                        navigate(page.route);
                      }}
                      selected={location.pathname.startsWith(page.route)}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          fontWeight: location.pathname.startsWith(page.route) ? '500' : '400',
                        }}
                      >
                        {page.name}
                      </Typography>
                    </NavBarMenuItem>
                  ))}
                  <NavBarMenuItem
                      key={'FAQ'}
                      id={'FAQ'}
                      onClick={async () => {
                        window.open('https://bdtmsd.sharepoint.com/sites/ai', '_blank')
                      }}
                    >
                      <Typography
                        textAlign="center"
                      >
                        {'FAQ'}
                      </Typography>
                      <OpenInNew sx={{ marginLeft: '5px', height: '20px', width: '20px' }} />
                    </NavBarMenuItem>
                </RoutesContainer>

                <StyledBox>
                  <Tooltip
                    title={<Typography variant="caption"> Submit Feedback </Typography>}
                    sx={{ height: '40px', width: '40px' }}
                  >
                    <IconButton sx={{color: 'white'}} onClick={() => handleFeedback()}>
                      <FeedbackOutlined />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={<Typography variant="caption"> Open settings </Typography>}
                    sx={{ height: '40px', width: '40px' }}
                  >
                    <IconButton sx={{color: 'white'}} onClick={handleOpenUserMenu}>
                      {isAdmin ? (
                        <AdminPanelSettings />
                      ) : (
                        <Person />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem onClick={() => handleLogout()}>
                      <Typography textAlign="center">
                        <IconContainer>
                          <LogoutOutlined />
                          <span>Logout</span>
                        </IconContainer>
                      </Typography>
                    </MenuItem>
                  </Menu>
                </StyledBox>
              </>
            ) : (
              <LogoContainer onClick={() => navigate('/')}>
                <img src={BDTMSD} alt='BDT MSD Logo' style={{ height: '30px' }} />
              </LogoContainer>
            )}
          </NavBarContainer>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};

export default NavBar;
