import { User } from 'Types/user';

import { API_URL, enrichedFetch } from './api';

export const updateUser = async (user: User): Promise<User> => {
  return enrichedFetch(`${API_URL}/user/update`, {
    method: 'PUT',
    body: JSON.stringify(user),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<User>;
};
