import { HomePageStats, UserStats } from '../Types/stats';
import { API_URL, enrichedFetch } from './api';

export const getHomePageStats = (): Promise<HomePageStats> => {
  return enrichedFetch(`${API_URL}/stats/home`) as Promise<HomePageStats>;
};

export const getUserStats = (): Promise<UserStats> => {
  return enrichedFetch(`${API_URL}/stats/users`) as Promise<UserStats>;
};
