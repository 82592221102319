import { User } from 'Types/user';

import { Prompt } from '../Types/prompt';
import { API_URL, enrichedFetch } from './api';

export const updateRelation = async (
  userId: User['id'],
  promptId: Prompt['id'],
  pinned?: boolean,
  liked?: boolean
): Promise<void> => {
  let url = `${API_URL}/user_prompt_relations?userId=${userId}&promptId=${promptId}`;

  if (pinned !== undefined) {
    url += `&pinned=${pinned}`;
  }
  if (liked !== undefined) {
    url += `&liked=${liked}`;
  }

  (await enrichedFetch(url, { method: 'PUT' })) as Promise<Prompt[]>;
};
