import styled from '@emotion/styled';
import { MoreHoriz } from '@mui/icons-material';
import { CardContent, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import * as filesaver from 'file-saver';
import React, { useState } from 'react';

import { deleteConversation, downloadFile, updateConversation } from '../../services/conversation';
import { Conversation } from '../../Types/conversation';
import prettyFormatDate from '../../util/timeUtils';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import RenameDialog from './RenameDialog';

const ConversationCard = styled('div')<{ selected: boolean }>(({ selected }) => ({
  width: '100%',
  ':hover': {
    cursor: 'pointer',
    boxShadow: '0px 4px 8px 4px rgba(16, 24, 40, 0.05)',
  },
  borderRadius: '4px',
  boxShadow: '0px 4px 8px 4px rgba(16, 24, 40, 0.01)',

  WebkitTapHighlightColor: 'transparent',
  display: 'block',
  WebkitTextDecoration: 'none',
  textDecoration: 'none',
  background: selected ? '#e2effc' : 'white',
  border: selected ? '1px solid #334466' : '1px solid #bfb6ba',
  boxSizing: 'border-box',
  position: 'relative',
  overflow: 'hidden',
  padding: '0.2rem',
}));

const StyledConversationCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '10px',
  padding: '10px',
  ':last-child': {
    paddingBottom: '10px',
  },
});

const IconButtonContainer = styled('div')({
  right: '0px',
  top: '0px',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
});

const ChatCard = ({
  onConversationCardClick,
  conversation,
}: {
  conversation: Conversation;
  onConversationCardClick: (conversationId?: string) => void;
}) => {
  const [anchorContextMenu, setAnchorContextMenu] = useState<null | HTMLElement>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);

  const handleConversationDelete = async (conversationId: string) => {
    await deleteConversation(conversationId);
    onConversationCardClick();
  };

  const handleOpenChatContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorContextMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorContextMenu(null);
  };

  const handleConversationRename = async (conversationId: string, newTitle: string) => {
    await updateConversation({ title: newTitle }, conversationId);
    onConversationCardClick(conversationId);
    handleCloseUserMenu();
  };

  function cleanMarkdown(input: string): string {
    let output = input;

    // Remove bold, italic, and strikethrough
    // eslint complains about this but it is necessary in the regex, so ignore it
    // eslint-disable-next-line no-useless-escape
    output = output.replace(/(\*{1,2}|_{1,2}|\~{1,2})(.+?)\1/g, '$2');

    // Remove inline code blocks
    output = output.replace(/`{1,3}([\s\S]+?)`{1,3}/g, '$1');

    // Remove headers
    output = output.replace(/#{1,6}\s(.+)/g, '$1');

    // Remove blockquotes
    output = output.replace(/> (.+)/g, '$1');

    // Remove horizontal rules
    output = output.replace(/(-{3,}|\*{3,})/g, '');

    return output;
  }

  const handleConversationDownload = async (conversation: Conversation) => {
    const response = await downloadFile(conversation.id)
    filesaver.saveAs(
      new Blob([cleanMarkdown(response.conversation)]),
      `${
        conversation.title.length > 50
          ? conversation.title.slice(0, 50).concat('...')
          : conversation.title
      }.txt`
    );
  };

  return (
    <ConversationCard
      onClick={() => onConversationCardClick(conversation.id)}
      selected={window.location.pathname.includes(conversation.id)}
    >
      <StyledConversationCardContent>
        <IconButtonContainer>
          <Typography>
            {conversation.title.length > 50
              ? conversation.title.slice(0, 50).concat('...')
              : conversation.title}
          </Typography>
          <div onClick={(e) => e.stopPropagation()}>
            <DeleteConfirmationDialog
              isOpen={isDeleteDialogOpen}
              setIsOpen={setIsDeleteDialogOpen}
              onDelete={() => handleConversationDelete(conversation.id)}
            />
            <RenameDialog
              isOpen={isRenameDialogOpen}
              setIsOpen={setIsRenameDialogOpen}
              currentName={conversation.title}
              onRename={(newTitle) => handleConversationRename(conversation.id, newTitle)}
            />
            <Tooltip title={<Typography variant="caption"> Open settings </Typography>}>
              <IconButton onClick={handleOpenChatContextMenu} sx={{ p: 0 }}>
                <MoreHoriz />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '32px' }}
              anchorEl={anchorContextMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorContextMenu)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={() => setIsRenameDialogOpen(true)}>
                <Typography textAlign="center">Rename</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleConversationDownload(conversation)}>
                <Typography textAlign="center">Download Conversation (.txt)</Typography>
              </MenuItem>
              <MenuItem onClick={() => setIsDeleteDialogOpen(true)}>
                <Typography textAlign="center">Delete</Typography>
              </MenuItem>
            </Menu>
          </div>
        </IconButtonContainer>
        <Typography variant="caption" sx={{ color: 'rgba(0,0,0,0.5)' }}>
          {prettyFormatDate(new Date(conversation.createdAt))}
        </Typography>
      </StyledConversationCardContent>
    </ConversationCard>
  );
};

export default ChatCard;
