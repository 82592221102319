import { Person } from '@mui/icons-material';
import { keyframes } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import remarkGfm from 'remark-gfm';

import { MessageFrom } from '../../enums';
import { Message } from '../../Types/conversation';
import MarkdownTable from '../MarkdownTable';
import CodeCopyButton from './CodeCopyButton';
import Roundtable from '../../assets/Roundtable.png';
import RoundtableNavy from '../../assets/Roundtable-navy.svg';

const StyledSyntaxHighlighter = styled(SyntaxHighlighter)({
  borderRadius: '4px',
});

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const ChatBubbleContainer = styled('div')({
  '& > *': {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
});

const ChatIcon = styled('img')({
  width: '30px',
  height: '30px',
  margin: '10px 10px 10px 0px'
});

const SpinningChatIcon = styled(ChatIcon)({
  animation: `${rotateAnimation} 2s linear infinite`,
});

const PersonIcon = styled(Person)({
  width: '30px',
  height: '30px',
  margin: '10px 10px 10px 0px'
});

const CodePre = styled('pre')({
  position: 'relative',
  overflowX: 'auto',
});

const ChatCard = styled('div')(({ sender }: { sender: MessageFrom }) => ({
  borderRadius: '4px',
  marginTop: 'auto',
  marginBottom: 'auto',
  outline: 0,
  WebkitTapHighlightColor: 'transparent',
  WebkitTextDecoration: 'none',
  textDecoration: 'none',
  color: '#000',
  boxSizing: 'border-box',
  position: 'relative',
  padding: '1rem',
  boxShadow: sender === 'USER' ? 'none' : '0px 4px 8px 4px rgba(16, 24, 40, 0.05)',
  backgroundColor: sender === 'USER' ? '#e2effc' : 'white',

  display: 'flex'
}));

const renderMessage = (message: string, citations: string[]) => {
  if (citations && citations.length > 0) {
    const formatToMarkdown = (text: string, links: string[]): string => {
      const markdownLinks = links.map((citation) => `- ${citation}`).join('\n');
      return `${text}\n\nCitations:\n${markdownLinks}`;
    };
    return formatToMarkdown(message, citations);
  }

  return message;
};

const ChatBubbleComponent = ({
  message,
  loading = false,
  title = "",
}: {
  message: Partial<Message>;
  loading?: boolean;
  title?: string;
}) => {
  return (
    <ChatCard
      sender={message.from ?? MessageFrom.AI}
      sx={{ opacity: loading ? '40%' : '100%', transition: '0.5s' }}
    >
      {message.from === MessageFrom.USER ? (
        <PersonIcon />
      ) : loading ? (
        <SpinningChatIcon src={Roundtable} alt="BDTMSD Roundtable" />
      ) : (
        <ChatIcon src={RoundtableNavy} alt="BDTMSD Roundtable" />
      )}
      <ChatBubbleContainer sx={{ lineHeight: '1.4em', width: 'calc(100% - 60px)' }}>
        {title ? (
          <p style={{ fontSize: '20px', fontWeight: '700', marginTop: '15px' }}>{title}</p>
        ): null}
        {message.from === MessageFrom.AI ? (
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              pre: ({ className, children }) => (
                <CodePre className={className}>
                  <CodeCopyButton>{children}</CodeCopyButton>
                  {children}
                </CodePre>
              ),
              table: (props) => <MarkdownTable {...props} />,
              code({ inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '');
                return !inline && match ? (
                  <StyledSyntaxHighlighter
                    {...props}
                    language={match[1]}
                    style={a11yDark}
                    PreTag="div"
                  >
                    {String(children).replace(/\n$/, '')}
                  </StyledSyntaxHighlighter>
                ) : (
                  <code {...props} style={{ wordWrap: 'normal' }} className={className}>
                    {children}
                  </code>
                );
              },
            }}
          >
            {renderMessage(message.message ?? '', message.citations ?? [])}
          </ReactMarkdown>
        ) : (
          <p style={{ whiteSpace: 'pre-wrap' }}>{message.message}</p>
        )}
      </ChatBubbleContainer>
    </ChatCard>
  );
};

export default ChatBubbleComponent;
