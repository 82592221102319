import {
  Conversation,
  CreateConversation,
  CreateMessage,
  DownloadConversationResponse,
  Message,
  UpdateConversation,
} from '../Types/conversation';
import { API_URL, enrichedFetch } from './api';

export const getConversations = (): Promise<Conversation[]> => {
  return enrichedFetch(`${API_URL}/conversations`) as Promise<Conversation[]>;
};

export const getConversation = (id: Conversation['id']): Promise<Conversation> => {
  return enrichedFetch(`${API_URL}/conversations/${id}`) as Promise<Conversation>;
};

export const postConversation = (conversation: CreateConversation): Promise<Conversation> => {
  return enrichedFetch(`${API_URL}/conversations`, {
    method: 'POST',
    body: JSON.stringify(conversation),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Conversation>;
};

export const updateConversation = async (
  updatedFields: UpdateConversation,
  conversationId: Conversation['id']
): Promise<void> => {
  await enrichedFetch(`${API_URL}/conversations/${conversationId}`, {
    method: 'PUT',
    body: JSON.stringify(updatedFields),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const deleteConversation = async (conversationId: Conversation['id']): Promise<void> => {
  await enrichedFetch(`${API_URL}/conversations/${conversationId}`, {
    method: 'DELETE',
  });
};

export const getMessages = (conversationId: Conversation['id']): Promise<Message[]> => {
  return enrichedFetch(`${API_URL}/conversations/messages/${conversationId}`) as Promise<Message[]>;
};

export const postMessage = (message: CreateMessage, llm: string): Promise<Message> => {
  return enrichedFetch(`${API_URL}/conversations/messages`, {
    method: 'POST',
    body: JSON.stringify({ message, llm }),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Message>;
};

export const downloadFile = (
  conversationId: Conversation['id']
): Promise<DownloadConversationResponse> => {
  return enrichedFetch(`${API_URL}/conversations/download/${conversationId}`, {
    method: 'GET',
  }) as Promise<DownloadConversationResponse>;
};
