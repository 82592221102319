import styled from '@emotion/styled';
import { Alert, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../components/Providers/AuthProvider';
import LoginSplash from '../assets/loginSplash.png'

const MainContainer = styled('div')({
  height: '100vh',
  width: '100vw',
  display: 'flex',
  background: '#010207',
});

const SplashImage = styled('img')({
  position: 'absolute',
  top: 0,
  right: 0,
  height: '100%',
  zIndex: 1
})

const LoginPage = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'inherit',
  minWidth: '50%',
});

const LoginSection = styled('div')({
  backgroundColor: 'inherit',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '40px',
  marginLeft: '10px',
  marginRight: '10px',
  width: '360px',
  height: 'fit-content',
  '> *:not(:last-child)': {
    marginBottom: '20px',
  },
  zIndex: 2,

  marginBottom: '60px',

  '@media (min-width: 1200px)': {
    marginBottom: '100px',
  },
});

const DisclaimerAlert = styled(Alert)({
  backgroundColor: '#D3EAFF',
  position: 'absolute',
  bottom: '32px',
  left: '20px',
});

const SSOLoginButton = styled(Button)({
  backgroundColor: 'white',
  color: 'black',

  '&:hover': {
    backgroundColor: '#E2EFFC'
  }
});

const Login = (): JSX.Element => {
  const navigate = useNavigate();
  // use the auth context to get the login function
  const { currentUser, authenticateAzureAD } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  const ssoLogin = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectTo = searchParams.get('redirect') || '/';
    authenticateAzureAD(redirectTo);
  };

  return (
    <MainContainer>
      <SplashImage src={LoginSplash} alt='' />
      <LoginPage>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoginSection>
            <Typography sx={{ fontWeight: '700', fontSize: '34px' }}>Say Hello <br/> to Beacon.</Typography>
            <Typography sx={{ fontWeight: '400', fontSize: '26px', color: '#6cb0f2' }}>Generate ideas, summarize notes, or recap a meeting with our conversational AI chat bot.</Typography>
            <SSOLoginButton onClick={ssoLogin} variant="contained">
              Login with SSO
            </SSOLoginButton>
          </LoginSection>
        </div>
      </LoginPage>
      <DisclaimerAlert severity="info">This app is under active development.</DisclaimerAlert>
    </MainContainer>
  );
};

export default Login;
