import styled from '@emotion/styled';
import { MenuOpen, OpenInNew } from '@mui/icons-material';
import {
  Alert,
  Button,
  Container,
  Drawer,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import PromptCard from '../components/Prompts/PromptCard';
import PromptEdit from '../components/Prompts/PromptEdit';
import PromptFilters from '../components/Prompts/PromptFilters';
import { useAuth } from '../components/Providers/AuthProvider';
import { findPrompts } from '../services/prompts';
import { Tag } from '../Types/enums';
import { Prompt } from '../Types/prompt';
const MainDiv = styled('div')({
  display: 'flex',
  gap: '10px',
  height: 'inherit',
  div: {
    position: 'relative',
  },
});
type ContainerProps = {
  open: boolean;
  drawerWidth: string;
};
const DrawerContainer = styled('div')<ContainerProps>(({ open, drawerWidth }) => ({
  marginRight: open ? '10px' : 0,
  width: open ? `calc(${drawerWidth})` : 'auto',
}));

const IconContainer = styled('span')({
  color: 'gray',
  transition: 'all 0.2s ease-in-out',
  display: 'flex',
  height: '20px',
  position: 'absolute',
  top: '10px',
  left: '10px',
  span: {
    color: 'black',
    marginLeft: '5px',
  },
});

const PromptsContainer = styled('div')<ContainerProps>(({ open, drawerWidth }) => ({
  overflowY: 'auto',
  marginLeft: open ? 'auto' : `-${drawerWidth}`,
  width: `100%`,
  display: 'flex',
  justifyContent: 'center',
  zIndex: 1,
}));

const StyledAlert = styled(Alert)({
  marginBottom: '20px',
  marginTop: '20px',
  backgroundColor: '#D2D2DF',
  color: 'black',
  '.MuiAlert-icon': { display: 'flex', alignItems: 'center' },
});

const CardContainer = styled('div')({
  display: 'flex',
  gap: '10px',
  flexWrap: 'wrap',
  justifyContent: 'center',
  paddingBottom: '20px',
});

const StyledLink = styled('a')({
  color: 'black',
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline',
  },
});

type TagCounts = {
  [key in Tag]: number;
};
type TagCount = {
  tag: Tag;
  count: number;
};
const INITIAL_TAG_DEFAULTS = Object.values(Tag).reduce((acc, tag) => {
  acc[tag] = 0;
  return acc;
}, {} as TagCounts);
const Prompts = ({ publishedOnly }: { publishedOnly: boolean }): JSX.Element => {
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [shouldFetchPrompts, setShouldFetchPrompts] = useState<boolean>(true);
  const [editOpen, setEditOpen] = useState(false);
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [selectedTags, setSelectedTags] = useState<TagCount[]>([]);
  const [visiblePrompts, setVisiblePrompts] = useState<Prompt[]>([]);
  const [tagOptions, setTagOptions] = useState<TagCounts>(INITIAL_TAG_DEFAULTS);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(true);

  useEffect(() => {
    setShouldFetchPrompts(true);
  }, [publishedOnly]);

  useEffect(() => {
    const initialTagOptions = { ...INITIAL_TAG_DEFAULTS };
    setTagOptions(
      prompts.reduce((acc, prompt) => {
        prompt.tags.forEach((tag) => {
          if (acc[tag]) {
            acc[tag] += 1;
          } else {
            acc[tag] = 1;
          }
        });
        return acc;
      }, initialTagOptions)
    );
    setVisiblePrompts(prompts);
  }, [prompts]);

  useEffect(() => {
    const initialTagOptions = { ...INITIAL_TAG_DEFAULTS };
    setTagOptions(
      visiblePrompts.reduce((acc, prompt) => {
        prompt.tags.forEach((tag) => {
          if (acc[tag]) {
            acc[tag] += 1;
          } else {
            acc[tag] = 1;
          }
        });
        return acc;
      }, initialTagOptions)
    );
  }, [visiblePrompts]);

  const handleTagClick = (event: React.ChangeEvent<HTMLInputElement>, tag: string) => {
    let newTags = [] as TagCount[];
    if (event.target.checked) {
      newTags = [...selectedTags, { tag: tag as Tag, count: tagOptions[tag as Tag] }];
    } else {
      newTags = selectedTags.filter((t) => t.tag !== tag);
    }
    setSelectedTags(newTags);
    setVisiblePrompts(
      prompts.filter((prompt) => {
        if (newTags.every((element) => prompt.tags.includes(element.tag))) {
          return prompt;
        }
        return false;
      })
    );
  };

  useEffect(() => {
    const fetchPrompts = async () => {
      setPrompts(await findPrompts(publishedOnly, currentUser?.id));
      setShouldFetchPrompts(false);
      setLoading(false);
    };
    if (shouldFetchPrompts) {
      fetchPrompts();
    }
  }, [currentUser?.id, publishedOnly, shouldFetchPrompts]);

  const onSuccess = () => {
    setShouldFetchPrompts(true);
  };

  const drawerWidth = '350px';

  return (
    <MainDiv>
      <DrawerContainer open={filterDrawerOpen} drawerWidth={drawerWidth}>
        <Drawer
          variant="persistent"
          anchor="left"
          open={filterDrawerOpen}
          sx={{ width: drawerWidth, height: '100%' }}
        >
          <PromptFilters
            tagOptions={tagOptions}
            handleTagUpdate={handleTagClick}
            closeFilterDrawer={() => setFilterDrawerOpen(false)}
          />
        </Drawer>
      </DrawerContainer>
      <PromptsContainer open={filterDrawerOpen} drawerWidth={drawerWidth}>
        {filterDrawerOpen ? null : (
          <Tooltip title={<Typography variant="caption"> Open Filter Panel </Typography>}>
            <IconContainer onClick={() => setFilterDrawerOpen(!filterDrawerOpen)}>
              <MenuOpen sx={{ transform: 'rotate(180deg)', cursor: 'pointer' }} />
            </IconContainer>
          </Tooltip>
        )}
        <Container maxWidth="md">
          <StyledAlert icon={`👋`} style={{ display: 'flex', alignItems: 'center'}}>
            <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
              <span style={{ fontWeight: '700' }}>Pro tip:</span> if you want to learn more about
              prompts, check out this{' '}
              <StyledLink
                href={
                  'https://learn.microsoft.com/en-us/azure/ai-services/openai/concepts/prompt-engineering'
                }
                target="_blank"
              >
                <OpenInNew sx={{ verticalAlign: 'top', height: '18px' }} />
                <span style={{ verticalAlign: 'top' }}>Intro to Prompt Engineering</span>
              </StyledLink>
              . And if you really want to nerd out, see this {' '}
              <StyledLink href={
                  'https://platform.openai.com/docs/guides/prompt-engineering'
                }
                target="_blank">
                <OpenInNew sx={{ verticalAlign: 'top', height: '18px' }} />
                <span style={{ verticalAlign: 'top' }}>Prompt Engineering Guide</span>
              </StyledLink>
              .
            </Typography>
          </StyledAlert>
          <div>
            {!publishedOnly && (
              <Button
                sx={{ marginBottom: '20px', display: 'inline-block' }}
                variant="contained"
                onClick={() => setEditOpen(true)}
              >
                Create Prompt
              </Button>
            )}
          </div>
          <PromptEdit isOpen={editOpen} setIsOpen={setEditOpen} onSuccess={onSuccess} />
          {loading ? (
            <Grid container columnSpacing={-10} rowSpacing={2} sx={{ width: '100%' }}>
              {Array(6)
                .fill(null)
                .map((_, index) => (
                  <Grid key={index} item xs={12}>
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="101px"
                      animation="wave"
                      style={{
                        borderRadius: '20px',
                        backgroundColor: '#F3F6F4',
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
          ) : (
            <CardContainer>
              {visiblePrompts.length === 0 ? (
                <Typography>No prompts found for given filters</Typography>
              ) : (
                visiblePrompts.map((prompt) => {
                  if (prompt)
                    return (
                      <PromptCard
                        prompt={prompt}
                        key={prompt.id}
                        onPromptEdit={onSuccess}
                        publishedOnly={publishedOnly}
                      />
                    );
                  return null;
                })
              )}
            </CardContainer>
          )}
        </Container>
      </PromptsContainer>
    </MainDiv>
  );
};
export default Prompts;
