import './init';

import { createTheme, Grow, ThemeProvider } from '@mui/material';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import React, { useCallback } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './components/Providers/AuthProvider';
import { ErrorProvider } from './components/Providers/ErrorProvider';
import { FeatureProvider } from './components/Providers/FeatureProvider';
import { JoyrideProvider } from './components/Providers/JoyrideProvider';
import SnackbarCloseButton from './components/SnackbarCloseButton';
import ProvidedApp from './ProvidedApp';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '0px',
        },
        contained: {
          backgroundColor: '#181830',
          '&:hover': {
            backgroundColor: '#334466',
          },
          '&:active': {
            backgroundColor: '#6CB0F2',
          },
        },
        outlined: {
          color: '#181830',
          borderColor: '#181830',

          ':hover': {
            color: '#334466',
            borderColor: '#334466',
          },

          ':active': {
            color: '#6CB0F2',
            borderColor: '#6CB0F2',
          },
        },
      },
    },
  },
});

function App() {
  // For adding a close button to snackbars
  const SnackbarCloseButtonAction = useCallback(
    (key: SnackbarKey) => <SnackbarCloseButton snackbarKey={key} />,
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={15000}
        preventDuplicate={true}
        TransitionComponent={Grow}
        action={(key) => SnackbarCloseButtonAction(key)}
      >
        <BrowserRouter>
            <AuthProvider>
          <ErrorProvider>
              <FeatureProvider>
                <JoyrideProvider>
                  <ProvidedApp />
                </JoyrideProvider>
              </FeatureProvider>
          </ErrorProvider>
            </AuthProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
