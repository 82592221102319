import styled from '@emotion/styled';
import {
  Delete,
  Edit,
  Forward,
  ForwardOutlined,
  LinkOutlined,
  PushPin,
  PushPinOutlined,
  Timeline,
  VisibilityOutlined,
} from '@mui/icons-material';
import { Box, CardContent, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { deletePrompt } from '../../services/prompts';
import { updateRelation } from '../../services/userPromptRelations';
import { Tag } from '../../Types/enums';
import { Prompt } from '../../Types/prompt';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import { useAuth } from '../Providers/AuthProvider';
import PromptEdit from './PromptEdit';
import Verified from '../../assets/verified.svg';

const PromptCardComponent = styled('div')({
  width: '100%',
  height: '100%',
  ':hover': {
    cursor: 'pointer',
    boxShadow: '0px 4px 8px 4px rgba(16, 24, 40, 0.05)',
  },
  borderRadius: '12px',
  boxShadow: '0px 4px 8px 4px rgba(16, 24, 40, 0.01)',

  outline: 0,
  WebkitTapHighlightColor: 'transparent',
  display: 'block',
  WebkitTextDecoration: 'none',
  textDecoration: 'none',
  color: '#000',
  backgroundColor: '#F8FBFE',
  boxSizing: 'border-box',
  position: 'relative',
});

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
  height: '100%',
  padding: '10px',

  //overwritting the last child padding
  '&:last-child': {
    paddingBottom: '10px',
  },
});

interface PinContainerProps {
  pinned: boolean;
}

const PinContainer = styled('div')<PinContainerProps>(({ pinned }) => ({
  color: pinned ? '#0B6E27' : '#767676',
  border: pinned ? 'none' : '1px solid #767676',
  height: pinned ? '20px' : '18px',
  width: pinned ? '20px' : '18px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: pinned ? '#d6f5de' : '#F8FBFE',

  '& svg': {
    width: '14px',
    height: '14px',
  },

  ':hover': {
    cursor: 'pointer',
    backgroundColor: pinned ? '#9dd1ac' : '#d9d9d9',
  },
}));

const CardLeftSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  overflow: 'hidden',
  padding: '10px',
  flexGrow: 1,
  height: '100%',
});

const CardRightSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  gap: '10px',
  padding: '10px',
  minWidth: '120px',
  paddingRight: '2px',
});

const CopyButtonComponent = styled('div')({
  display: 'flex',
  position: 'absolute',
  cursor: 'pointer',
  width: '100%',
});

const IconButtonStyled = styled(IconButton)({
  color: 'gray',
  transition: 'all 0.2s ease-in-out',
  height: '20px',
  width: '20px',

  ':hover': {
    backgroundColor: '#d9d9d9',
  },

  '& svg': {
    width: '14px',
    height: '14px',
  },
});

interface LikeContainerProps {
  liked: boolean;
}

const LikeContainer = styled('div')<LikeContainerProps>(({ liked }) => ({
  color: liked ? '#014361' : '#767676',
  border: liked ? '1px solid #014361' : '1px solid #767676',
  height: '18px',
  width: '18px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '5px',
  marginLeft: '10px',
  backgroundColor: liked ? '#cce6ff' : '#F8FBFE',

  '& svg': {
    width: '14px',
    height: '14px',
  },

  ':hover': {
    cursor: 'pointer',
    backgroundColor: liked ? '#95c4da' : '#d9d9d9',
  },
}));

const PromptCard = ({
  prompt,
  onPromptEdit,
  publishedOnly,
}: {
  prompt: Prompt;
  onPromptEdit: () => void;
  publishedOnly: boolean;
}): JSX.Element => {
  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  // const [overflowCount, setOverflowCount] = useState<number>(0);
  // const [showOverflow, setShowOverflow] = useState<boolean>(false);
  const [pinTooltipOpen, setPinTooltipOpen] = useState<boolean>(false);

  const { currentUser, isAdmin } = useAuth();

  const iconSize: object = { height: '14px', width: '14px' };
  const iconStyle = {
    ...iconSize,
    color: 'gray',
  };
  const navigate = useNavigate();

  const handlePinned = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (currentUser) {
      setPinTooltipOpen(false);
      await updateRelation(currentUser.id, prompt.id, !prompt.pinned);
      onPromptEdit();
    }
  };

  const handleLiked = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (currentUser) {
      await updateRelation(currentUser.id, prompt.id, undefined, !prompt.liked);
      onPromptEdit();
    }
  };

  const handleCopy = async () => {
    try {
      let host = window.location.host;

      if(import.meta.env.VITE_ENVIRONMENT === 'local'){
        host = 'http://' + host;
      } else{
        host = 'https://' + host;
      }

      await navigator.clipboard.writeText(host + `/chat?prompt=${prompt.id}`);
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 1000);
    } catch (error) {
      enqueueSnackbar('Failed to copy to clipboard', {
        variant: 'error',
      });
    }
  };

  const handlePromptDelete = async (promptId: string) => {
    await deletePrompt(promptId);
    onPromptEdit();
  };

  const showTags = () => {
    const tags = [...prompt.tags].sort((a: Tag, b: Tag) => (a > b ? 1 : -1));
    const shownTags = [...tags];

    return (
      <div
        id={prompt.id}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '4px',
          height: '100%',
        }}
      >
        <Chip
          variant="outlined"
          label={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {prompt.published && !publishedOnly ? (
                <Tooltip title={<Typography variant="caption"> Published </Typography>}>
                  <img
                    style={{ marginRight: '5px', width: '14px', height: '14px' }}
                    alt='published'
                    src={Verified}
                  />
                </Tooltip>
              ) : null}
              Made by{' '}
              {currentUser?.id === prompt.createdBy
                ? 'me'
                : prompt.createdByName?.slice(0, prompt.createdByName.indexOf('@'))}
            </span>
          }
          sx={{ fontSize: '13px', fontWeight: '500', height: '24px' }}
        />
        {shownTags.map((tag: string, index: number) => (
          <Chip
            label={tag}
            key={index}
            sx={{ fontSize: '13px', fontWeight: '500', height: '24px' }}
          ></Chip>
        ))}
      </div>
    );
  };

  return (
    <PromptCardComponent onClick={() => navigate(`/chat?prompt=${prompt.id}`)}>
      <StyledCardContent>
        <CardLeftSection>
          <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <Tooltip
              title={<Typography variant="caption"> Pin this prompt </Typography>}
              open={pinTooltipOpen}
              onMouseEnter={() => setPinTooltipOpen(true)}
              onMouseLeave={() => setPinTooltipOpen(false)}
            >
              <PinContainer onClick={handlePinned} pinned={prompt.pinned || false}>
                {prompt.pinned ? <PushPin /> : <PushPinOutlined />}
              </PinContainer>
            </Tooltip>

            {prompt.featured && (
              <Box
                sx={{
                  backgroundColor: '#ffe9ca',
                  borderRadius: '4px',
                  color: '#d47202',
                  width: '85px',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '10px',
                  alignItems: 'center',
                  marginLeft: '10px',
                }}
              >
                <Typography sx={{ fontSize: '13px', fontWeight: '500' }}>FEATURED</Typography>
              </Box>
            )}
          </div>
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '16px',
            }}
          >
            {prompt.title}
          </Typography>
          <Typography
            id="description"
            sx={{
              height: '1.5rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: '14px',
              fontWeight: '500',
            }}
          >
            {prompt.description}
          </Typography>
          {showTags()}
        </CardLeftSection>
        <CardRightSection>
          <CopyButtonComponent>
            <div
              onClick={(e) => e.stopPropagation()}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
                paddingRight: '2px',
              }}
            >
              <PromptEdit
                readOnly={!isAdmin && prompt.createdBy !== currentUser?.id}
                prompt={prompt}
                isOpen={editOpen}
                setIsOpen={setEditOpen}
                onSuccess={onPromptEdit}
              />
              <DeleteConfirmationDialog
                isOpen={deleteOpen}
                setIsOpen={setDeleteOpen}
                onDelete={() => handlePromptDelete(prompt.id)}
              />
              <Tooltip title={<Typography variant="caption"> Copy link </Typography>}>
                <IconButtonStyled
                  onClick={handleCopy}
                  style={{ color: copySuccess ? 'rgb(171, 227, 56)' : 'gray' }}
                >
                  <LinkOutlined sx={iconSize} />
                </IconButtonStyled>
              </Tooltip>
              {!isAdmin && prompt.createdBy !== currentUser?.id && (
                <Tooltip title={<Typography variant="caption"> View </Typography>}>
                  <IconButtonStyled onClick={() => setEditOpen(true)}>
                    <VisibilityOutlined sx={iconSize} />
                  </IconButtonStyled>
                </Tooltip>
              )}
              {(isAdmin || prompt.createdBy === currentUser?.id) && (
                <>
                  <Tooltip title={<Typography variant="caption"> Edit </Typography>}>
                    <IconButtonStyled onClick={() => setEditOpen(true)}>
                      <Edit sx={iconSize} />
                    </IconButtonStyled>
                  </Tooltip>
                  <Tooltip title={<Typography variant="caption"> Delete </Typography>}>
                    <IconButtonStyled
                      sx={{ marginRight: '-1px' }}
                      onClick={() => setDeleteOpen(true)}
                    >
                      <Delete sx={iconSize} />
                    </IconButtonStyled>
                  </Tooltip>
                </>
              )}
            </div>
          </CopyButtonComponent>
          <Typography variant="subtitle2" sx={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'gray',
              }}
            >
              <Tooltip title={<Typography variant="caption"> Prompt Usages </Typography>}>
                <Timeline
                  sx={{
                    ...iconStyle,
                    paddingRight: '5px',
                  }}
                />
              </Tooltip>
              {prompt.usageCount}
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'gray',
                width: '50px',
              }}
            >
              <Tooltip title={<Typography variant="caption"> Like this prompt </Typography>}>
                <LikeContainer
                  onClick={handleLiked}
                  liked={prompt.liked || false}
                  style={{ transform: 'rotate(-90deg)' }}
                >
                  {prompt.liked ? <Forward /> : <ForwardOutlined />}
                </LikeContainer>
              </Tooltip>

              {prompt.likedCount || 0}
            </div>
          </Typography>
        </CardRightSection>
      </StyledCardContent>
    </PromptCardComponent>
  );
};

export default PromptCard;
