import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';

const MAX_NAME_LENGTH = 100;

const RenameDialog = ({
  onRename,
  currentName,
  isOpen,
  setIsOpen,
}: {
  onRename: (str: string) => Promise<void>;
  currentName: string;
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
}) => {
  const [name, setName] = useState(
    currentName.length > MAX_NAME_LENGTH
      ? currentName.slice(0, MAX_NAME_LENGTH).concat('...')
      : currentName
  );

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRename = () => {
    onRename(name);
    setName(name.length > MAX_NAME_LENGTH ? name.slice(0, MAX_NAME_LENGTH).concat('...') : name);
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">What would you like to name this chat?</DialogTitle>
        <DialogContent style={{ paddingTop: '10px' }}>
          <TextField
            label="Chat Name"
            type="text"
            value={name}
            onChange={handleNameChange}
            fullWidth
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRename} color="primary" autoFocus>
            Rename
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RenameDialog;
