import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';

const DeleteConfirmationDialog = ({
  onDelete,
  isOpen,
  setIsOpen,
}: {
  onDelete: () => Promise<void>;
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
}) => {
  const handleDelete = () => {
    onDelete();
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Are you sure you want to delete this item?'}
        </DialogTitle>
        <DialogContent>
          {/* Add additional information about the item to be deleted here */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteConfirmationDialog;
