import { AppConfigurationClient } from '@azure/app-configuration';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { useAuth } from './AuthProvider';

type Flags = { [name: string]: boolean };

export const FeatureContext = createContext<Flags | null>(null);
const client = new AppConfigurationClient(import.meta.env.VITE_AZURE_APP_CONFIG_CONNECTION_STRING);

const defaultFlags = {
  gpt4: false,
  gpt4_32k: false,
  gpt4o: false,
  o1preview: false
} as Flags;

interface Props {
  children: React.ReactNode;
}

export const FeatureProvider: React.FC<Props> = ({ children }) => {
  const { currentUser } = useAuth();
  const [flags, setFlags] = useState<Flags>(defaultFlags);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    async function fetchFeatureFlags() {
      try {
        const tempFlags = { ...defaultFlags };
        const flagPromises = Object.keys(tempFlags).map(async (flag: string) => {
          const result = await client.getConfigurationSetting({
            key: `.appconfig.featureflag/${flag.toString().trim()}`,
          });

          if (result && result.value) {
            const resultValue = JSON.parse(result.value);
            tempFlags[flag] = resultValue.enabled;

            if (
              resultValue.conditions.client_filters[0].parameters.Audience.Users.includes(
                currentUser?.email
              )
            ) {
              tempFlags[flag] = true;
            }

            if (resultValue.conditions.client_filters[0].parameters.Audience.Exclusion) {
              if (
                resultValue.conditions.client_filters[0].parameters.Audience.Exclusion.Users.includes(
                  currentUser?.email
                )
              ) {
                tempFlags[flag] = false;
              }
            }
          }
        });

        await Promise.all(flagPromises); // Wait for all promises to resolve
        setFlags(tempFlags);
        setInitialized(true);
      } catch (error) {
        console.error(error);
      }
    }

    if (!initialized && currentUser) {
      fetchFeatureFlags();
    }
  }, [initialized, currentUser]);

  return <FeatureContext.Provider value={flags}>{children}</FeatureContext.Provider>;
};

export const useFeatures = (): Flags => {
  const featureContext = useContext(FeatureContext);
  if (!featureContext) {
    throw new Error('useFeature must be used within a FeatureProvider');
  }
  return featureContext;
};
