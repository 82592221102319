import { LinearProgress, styled, Typography } from '@mui/material';
import React from 'react';

export type LimitWarningProps = {
  percentage: number;
};

const WarningContainer = styled('div')({
  '& > *': {
    display: 'inline-block',

    '&:first-of-type': {
      width: '85%',
      color: 'red',
      fontSize: '12px',
    },

    '&:last-of-type': {
      display: 'inline-block',
      width: '10%',
      verticalAlign: 'top',
      float: 'right',
    },
  },
});

const StyledProgress = styled(LinearProgress)<LimitWarningProps>(({ percentage }) => ({
  background: `linear-gradient(135deg, #D41D8A ${100 - percentage}%, #FF3D00 100%);`,
  borderRadius: '4px',
  height: 5,
  transition: 'background 2s ease-in-out',
}));

const LimitWarning = ({ percentage }: LimitWarningProps) => {
  return (
    <WarningContainer>
      {percentage > 0.75 ? (
        <>
          {percentage >= 1 ? (
            <Typography>
              Oops! The length of this conversation is at the AI models context limit. You may get one or two more responses, but most likely you will get an error, please start a new conversation. Thank you for your understanding!
            </Typography>
          ) : (
            <Typography>
              AI models can only handle so much data, and this conversation is nearing that limit.
              Proceed with caution!
            </Typography>
          )}
          <p>
            <StyledProgress variant="determinate" value={0} percentage={100 * percentage} />
          </p>
        </>
      ) : null}
    </WarningContainer>
  );
};

export default LimitWarning;
